<template>
	<div class="rechargeDetail fadeInRight">
		<div class="title-box clearfix">
			<span class="m-title">充值日志详情</span>
			<el-form :inline="true" class="right themed-form search-form" style="height: auto;line-height: 40px;" id="baseForm">
				<el-form-item label="来源:">
					<el-select placeholder="全部" v-model="searchIteam.source" filterable>
						<el-option label="全部" value=""></el-option>
						<el-option label="充值" value="1"></el-option>
						<el-option label="下单" value="2"></el-option>
						<el-option label="收益转入" value="3"></el-option>
						<el-option label="公众号下单" value="4"></el-option>
						<el-option label="接口下单" value="5"></el-option>
						<el-option label="减扣" value="6"></el-option>
						<el-option label="代理商微信预充值" value="7"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="类型:">
					<el-select placeholder="全部" v-model="searchIteam.type" filterable>
						<el-option label="全部" value=""></el-option>
						<el-option label="支出" value="1"></el-option>
						<el-option label="收入" value="2"></el-option>
					</el-select>
				</el-form-item>

				<el-form-item label="卡号：">
					<el-input v-model="searchIteam.pageSearchKeys" placeholder="卡号"></el-input>
				</el-form-item>
				<el-form-item label="订单：">
					<el-input v-model="searchIteam.orderNumber" placeholder="订单"></el-input>
				</el-form-item>
				<el-form-item label="操作时间:">
					<el-date-picker v-model="value1" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"
					 format="yyyy 年 MM 月 dd 日" value-format="yyyy-MM-dd">
					</el-date-picker>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="searchData" class="themed-button" icon="el-icon-search">搜索查询</el-button>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="exportExcel" class="themed-button" icon="el-icon-caret-left">导出excel</el-button>
				</el-form-item>
				<iframe name="hidden_down_iframe" id="hidden_down_iframe" style="display: none;" src=""></iframe>
			</el-form>
		</div>
		<div class="content-box">
			<el-table :data="tabledata" stripe  style="width: 100%;" ref="table" :max-height="maxTableHeight" border>
				<el-table-column prop="agentName" label="代理商名称">
				</el-table-column>
				<el-table-column prop="orderNumber" label="订单号">
				</el-table-column>
				<el-table-column prop="iccidMark" label="卡号">
				</el-table-column>
				<el-table-column prop="mealName" label="套餐名">
				</el-table-column>
				<el-table-column prop="mealType" label="套餐类型">
				</el-table-column>
				<el-table-column label="来源">
					<template slot-scope="scope">
						{{getRechargeOrig(scope.row)}}
					</template>
				</el-table-column>
				<el-table-column label="类型">
					<template slot-scope="scope1">
						{{getRechargeType(scope1.row)}}
					</template>
				</el-table-column>
				<el-table-column label="支付状态">
					<template slot-scope="scope2">
						{{getRechargeStatus(scope2.row)}}
					</template>
				</el-table-column>
				<el-table-column prop="oldMoney" label="原本金额">
				</el-table-column>
				<el-table-column prop="newMoney" label="操作金额">
				</el-table-column>
				<el-table-column prop="money" label="现在金额">
				</el-table-column>
				<el-table-column prop="descriptionLog" label="修改描述">
				</el-table-column>
				<el-table-column prop="operator" label="操作人" width="105px">
				</el-table-column>
				<el-table-column prop="createDatetime" label="操作时间" width="200px">
				</el-table-column>
			</el-table>
			<el-pagination :current-page.sync="page" :page-sizes="[10,15,25,100]" :page-size="pageSize" style="text-align: left;margin-top: 20px;"
			 background layout="total,sizes,prev, pager, next,jumper" :total="total" @size-change="handleSizeChange"
			 @current-change="onPageChange">
			</el-pagination>
		</div>
	</div>
</template>

<script>
	import onresize from "@/mixins/resize.js"
	import loading from '@/utils/Loading.js'
	import {
		rechargeLoglist ,agentrechargelogExport
	} from "@/api/agentManagement.js"
	export default {
		name:'rechargeDetail',
		mixins: [onresize],
		data() {
			return {
				searchIteam: {
					type: "",
					originOper: "",
					agentId: this.$route.query.agentId,
					source: "",
					pageSearchKeys: "",
					orderNumber: "",
					startTime: "",
					endTime: "",
				},
				value1: [],
				page: 1,
				pageSize: 15,
				total: 0,
				tabledata: [],

			}
		},
		created() {
			this.getRechargeLoglist(this.searchIteam)
		},
		watch:{
			value1(value){
				//console.log(value)
				this.searchIteam.startTime = value == null ? "" : value[0]
				this.searchIteam.endTime = value == null ? "" : value[1]
			}
		},
		methods: {
			getRechargeLoglist(params) {
				rechargeLoglist(params).then((res) => {
					//console.log(res)
					this.tabledata = res.rows
					this.page = res.pageNo
					this.pageSize = res.pageSize
					this.total = res.total
				})
			},
			getRechargeOrig(rowData) {
				if (rowData.source == 1) return "充值"
				else if (rowData.source == 2) return "下单"
				else if (rowData.source == 3) return "收益转入"
				else if (rowData.source == 4) return "微信下单"
				else if (rowData.source == 5) return "api下单"
				else if (rowData.source == 6) return "减扣"
				else if (rowData.source == 7) return "代理商微信预充值"
			},
			getRechargeType(rowData) {
				if (rowData.type == 1) return "支出"
				else if (rowData.type == 2) return "收入"
			},
			getRechargeStatus(rowData) {
				if (rowData.source == 2 || rowData.source == 4 || rowData.source == 5) {
					if (rowData.payStatusInt == 1) return "已支付"
					else return "已退款"
				} else return "已支付"
			},
			searchData() {
				let params = {
					pageSize: this.pageSize,
					pageNo: this.page,
				}
			
				Object.assign(params, this.searchIteam)
				
				this.getRechargeLoglist(params)
			},
			/* exportExcel() {
				let agentId = this.searchIteam.agentId == undefined ? "" : this.searchIteam.agentId
				let source = this.searchIteam.source == undefined ? "" : this.searchIteam.source
				let type = this.searchIteam.type == undefined ? "" : this.searchIteam.type
				let pageSearchKeys = this.searchIteam.pageSearchKeys == undefined ? "" : this.searchIteam.pageSearchKeys
				let orderNumber = this.searchIteam.orderNumber == undefined ? "" : this.searchIteam.orderNumber
				let startTime = this.searchIteam.startTime == undefined ? "" : this.searchIteam.startTime
				let endTime = this.searchIteam.endTime == undefined ? "" : this.searchIteam.endTime
				let url = "https://iottest.sunshineiot.net/traffic-web/agent/agentrechargelog/export2excel.do?" + "agentId=" +
					agentId + "&source=" + source + "&type=" + type + "&pageSearchKeys=" + pageSearchKeys + "&orderNumber=" +
					orderNumber + "&startTime=" + startTime + "&endTime=" + endTime;
				url = encodeURI(encodeURI(url));
				document.getElementById("hidden_down_iframe").src = url;
			}, */
				exportExcel(){
							loading.show()
							let params = {
								agentId:this.searchIteam.agentId == undefined ? "" : this.searchIteam.agentId,
								source:this.searchIteam.source == undefined ? "" : this.searchIteam.source,
								type:this.searchIteam.type == undefined ? "" : this.searchIteam.type,
								pageSearchKeys:this.searchIteam.pageSearchKeys == undefined ? "" : this.searchIteam.pageSearchKeys,
								orderNumber:this.searchIteam.orderNumber == undefined ? "" : this.searchIteam.orderNumber,
								startTime:this.searchIteam.endTime == undefined ? "" : this.searchIteam.endTime,
								endTime:this.searchIteam.endTime == undefined ? "" : this.searchIteam.endTime
							}
							agentrechargelogExport(params).then(res=>{
								if (res.system_result_key == '0') {
									this.$alert('导出成功，请到导出记录中下载', '提示', {
									    confirmButtonText:'确定',
									}).then(()=>{
										this.$router.push('/tob/i_export_list')
									});
								}else{
									this.$message({
									    showClose: true,
									    message: res.system_result_message_key,
									    type: 'error'
									});
								}
							})
						},
			handleSizeChange(size) {
				this.pageSize = size
				let params = {
					pageSize: this.pageSize,
					pageNo: this.page,
				}
				Object.assign(params, this.searchIteam)
				console.log(params)
				this.getRechargeLoglist(params)
			},
			onPageChange(page) {
				this.page = page
				let params = {
					pageSize: this.pageSize,
					pageNo: this.page,
				}
				Object.assign(params, this.searchIteam)
				console.log(params)
				this.getRechargeLoglist(params)
			},

		},
	}
</script>

<style>
.rechargeDetail .el-form-item__label {vertical-align: top;}
</style>
